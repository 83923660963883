import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import myeekaImg from "src/images/about-us/Myeeka Calhoun.jpg";
import carmenImg from "src/images/about-us/Carmen Hidalgo.jpg";

export default function Page() {
  return (
    <BlogLayout slug="meet-new-leaders">
      <Paragraph component="div">
        <Markdown>
          {`Myeeka Calhoun and Carmen Hidalgo both work at EOCP's Crossroads shelter. They joined the organization a year ago and have brought a renewed spirit to assisting unhoused individuals and families. They share their perspectives on EOCP and why working in homeless services is important to them.
          
[Join Myeeka and Carmen and support EOCP's work today.](https://www.paypal.com/donate/?hosted_button_id=G93HRCX7NCCL6)`}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Meet Myeeka
        <Paragraph>Myeeka Calhoun, Case Management Services Manager</Paragraph>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={myeekaImg}
          sx={{ width: "40%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          {`I've always known about EOCP's powerful work --- in my past jobs with young people and veterans, I have referred many people over the years to Crossroads shelter or the Supportive Services for Veteran Families (SSVF) program at EOCP. I knew that Wendy Jackson was a powerhouse, and that EOCP was a long-standing organization doing work for *my* community --- I always tell people, even with residents: I am you, you are me. I have close family members who are right now facing the same struggles as those who come in the door at Crossroads, so this work is near and dear to my heart.

It was still the peak of the pandemic when I joined EOCP, and so much has changed in that short time. When I first started my role, at the peak of the pandemic, I spent a lot of time listening to Wendy Jackson, and understanding the historical roots and vision of the organization. That time with Wendy was so unique and valuable, because this is the first time I've worked for an organization that had an African American Executive Director whose leadership had nurtured something homegrown and grassroots into what is now a community staple. **From the very beginning of my time here, I have felt seen at EOCP** --- in all my Blackness, as a woman, and with my fierce advocacy. I have been supported to be myself, to share my ideas, and to grow.

While our roots are as a Black-led organization, **I am really encouraged to see people join our team from many different walks of life**, because we're not only serving African Americans --- we're serving the community at large, and a lack of a connection to services is more of a class and poverty issue, than a racial issue. The passion of EOCP leaders is felt, and it's not forced or contrived --- it's authentic. As things change, it's good to see our organization progress and expand. I hope that as we keep evolving, we also keep honoring EOCP's grassroots history.

I am excited to keep doing what I can to put EOCP on the map, strengthen our community partnerships, and be creative in how we ensure resources for consistent care for our clients. To better connect people to available services at EOCP, I spend time building relationships with housing one-on-one navigational centers, and a wide range of partner agencies and planning councils to make sure people know EOCP not only has beds for these clients, but significant on-site case management, medical, and housing placement support. When it comes to housing placements, our relationships with local landlords and creative approaches to getting folks out of the shelter (for example, by matching roommates to help share costs) have also helped us meet our goals.

I believe in our model for community care, especially as it relates to **the highest goal we're working on: End chronic homelessness**. I keep that in writing, on the wall in my office. We're here to get people off the streets, and connect them to underutilized resources. On the way to that goal, I wake up every morning pushing to have people in a better position from the day they come in to Crossroads, to the day they leave --- whether it's as small as a smile, or gaining weight because they're eating full meals, or as big as moving out of the shelter to their own new home.

My wonderful and intentional case management team is so committed --- I work with the best people. They show up for our clients, even when life is hard. There are challenging days, but I'm here for the long haul. I want to keep honoring and pushing that hard line about EOCP, because it's so significant in our community.`}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Meet Carmen
        <Paragraph>Carmen Hidalgo, Family Services Manager</Paragraph>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={carmenImg}
          sx={{ width: "40%", float: "right" }}
          ml={3}
          mb={3}
        />
        <Markdown>
          {`I've always worked with families. Before joining EOCP about 9 months ago, I was involved with juvenile dependency work --- and housing and homelessness was the #1 issue I encountered when working with families. A former supervisor once said to me: **"If we can impact homelessness, we can impact families and keep them whole." I always held onto that.** When the opportunity to join EOCP came up, I knew it felt like the next right move --- and, I have lived in East Oakland for several years, and wanted to make an impact in my immediate community, too.

My main priority has been to **build a strong culture of care and support with our amazing case management team**, so we can show up every day for our families with the energy they need. We have been working on having consistent meetings so that each family feels more connected with both our case managers and with the other families in the shelter: We want to encourage parents and kids to be each other's eyes and ears, looking out for each other as part of a community. With my team, we're thinking a lot about how to strengthen our communication with each other, especially as we stay proactively aware of issues that might arise with our families.

Another priority has been to transform our physical spaces so that residents can feel a sense of comfort and stability while they stay at Crossroads. For example, we're finally getting a new play structure, and we're refreshing our children's library. These things really make a difference for parents and kids, but also for our case management team, who can then spend their time giving each family one-on-one attention for their housing placement, or to connect them with medical or mental health care.

We're successfully placing families into housing. Among those exciting wins, there have been some major hurdles over the past few months, and every day is challenging. But, any time of change also brings new energy --- which helps me to stay present and engaged every day with our families at Crossroads.`}
        </Markdown>
      </Paragraph>
    </BlogLayout>
  );
}
